import React from 'react'
import './App.css';
import MainRouter from './MainRouter';
import MainRouterTest from './MainRouterTest';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './theme/GlobalStyles';
import { lightTheme, darkTheme } from './theme/themes';
import useDarkMode from './components/useDarkMode';
import AppContext from './AppContext';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {

	const [theme, toggleTheme] = useDarkMode();

	return (
		<AppContext.Provider value={{ theme, toggleTheme }}>
			<ThemeProvider theme={ theme === 'light' ? lightTheme : darkTheme }>
				<GlobalStyles />
				<div className="App">
					<Router>
						<MainRouter />
						{/* <MainRouterTest /> */}
					</Router>
				</div>
			</ThemeProvider>
		</AppContext.Provider>
  );
}

export default App;
