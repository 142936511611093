import React from 'react';
import DarkModeToggle from 'react-dark-mode-toggle';
import AppContext from '../AppContext';

function ThemeToggler() {
	const themeContext = React.useContext(AppContext);

  return (
    <>
			<div style={{ marginBottom: 8 }}>
				<DarkModeToggle
					onChange={themeContext.toggleTheme}
					checked={themeContext.theme === 'light' ? false : true}
					size={50}
				/>
			</div>
    </>
  );
}

export default ThemeToggler;
