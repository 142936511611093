import React, { useState, useEffect, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import FallbackSpinner from './components/FallbackSpinner';
import NavBar from './components/NavBar';
import endpoints from './constants/endpoints';
// import ReactFullpage from "@fullpage/react-fullpage";
import { Element } from 'react-scroll';

const Home = React.lazy(() => import('./components/Home'));
const About = React.lazy(() => import('./components/About'));
const Skills = React.lazy(() => import('./components/Skills'));
const Education = React.lazy(() => import('./components/Education'));
const Projects = React.lazy(() => import('./components/Projects'));
const Footer = React.lazy(() => import('./components/Footer'));

function MainRouter() {
	const [data, setData] = useState(null);

	useEffect(() => {
		fetch(endpoints.routes, {
			method: 'GET',
		})
			.then((res) => res.json())
			.then((res) => setData(res))
			.catch((err) => err);
	}, []);

	return (
		<div className="MainApp">
			<NavBar />
			<main className="main">
				<Suspense fallback={<FallbackSpinner />}>
					{/* <ReactFullpage
						licenseKey={'GPLv3'}
						scrollingSpeed={1000}
						navigation
						menu="navbar-custom"
						scrollBar={false}
						activeClassName='active'
						anchors={['home', 'about',]}
						navigationTooltips={['Home', 'About']}
						showActiveTooltip={true}
						slidesNavigation={false}
						render={({ fullpageApi }) => {
							return (
								<ReactFullpage.Wrapper>
									<div className="section fp-auto-height" id='home'>
										<Element name='home'>

										<Home />
										</Element>
									</div>
									<div className="section fp-auto-height" id='about'>
										<Element name='about'>

										<About />
										</Element>
									</div>
								</ReactFullpage.Wrapper>
							)
						}}
					/> */}
				{/* <Home /> */}
					{/* <Routes> */}
						{/* {data
							&& data.sections.map((route) => {
								const SectionComponent = React.lazy(() => import('./components/' + route.component));
								return (
									// <Route
									//   key={route.headerTitle}
									//   path={route.path}
									//   component={() => (
										<SectionComponent header={route.headerTitle} />
										//   )}
										// />
										);
									})} */}
									<Footer />
					{/* </Routes> */}
				</Suspense>
			</main>
		</div>
	)
}

export default MainRouter