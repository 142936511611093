import React, { useState, useEffect, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import FallbackSpinner from './components/FallbackSpinner';
import NavBar from './components/NavBar';
import endpoints from './constants/endpoints';

const Home = React.lazy(() => import('./components/Home'));
const About = React.lazy(() => import('./components/About'));
const Skills = React.lazy(() => import('./components/Skills'));
const Education = React.lazy(() => import('./components/Education'));
const Projects = React.lazy(() => import('./components/Projects'));
const Footer = React.lazy(() => import('./components/Footer'));

function MainRouter() {
	const [data, setData] = useState(null);

	useEffect(() => {
		fetch(endpoints.routes, {
			method: 'GET',
		})
			.then((res) => res.json())
			.then((res) => setData(res))
			.catch((err) => err);
	}, []);

	return (
		<div className="MainApp">
			<main id="App" className="main">
				<Suspense fallback={<FallbackSpinner />}>
			<NavBar />
				<Home />
					{/* <Routes> */}
						{data
							&& data.sections.map((route) => {
								const SectionComponent = React.lazy(() => import('./components/' + route.component));
								return (
									// <Route
									//   key={route.headerTitle}
									//   path={route.path}
									//   component={() => (
										<SectionComponent header={route.headerTitle} />
										//   )}
										// />
										);
									})}
									<Footer />
					{/* </Routes> */}
				</Suspense>
			</main>
		</div>
	)
}

export default MainRouter